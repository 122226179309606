/**
 *
 * Composant affichant un Tag
 * @returns JSX.element
 */
function Tag({ children }) {
   return <div className="tag">{children}</div>;
}

export default Tag;
